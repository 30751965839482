<script setup>

  import { useRouter } from 'vue-router';
  import axios from 'axios';

  import { useStore } from 'vuex';
  import { ref } from 'vue';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';
  import { onBeforeMount } from 'vue';

  axios.defaults.withCredentials = true;


  const router = useRouter();
  const store = useStore();

  const authenticated = ref(false);
  const userGameKarmaPoints = ref(null);
  const gameKarmaPointsHolderFontSize = ref('70');
  const loadingImagePlaceholderPath = 'images/loading-image-placeholder.png';
  const loading = ref(true);

  const notification = ref('');
  const notificationPresent = ref(false);

  const itemsToPurchase = ref([
    {
      title: '1 Month of GamePass Ultimate',
      description: 'Get over 400 high-quality games on Xbox One, Windows 10, plu Xbox Live Gold - $20 value',
      cost: 1000,
      imageUri: 'https://cdn.cdkeys.com/700x700/media/catalog/product/a/f/affeaf_10_16_10__2.jpg',
      imageLoaded: true
    },
    {
      title: 'Personalized Game Recommendations',
      description: 'Our gaming pros will recommend the best games for you to play based on your preferences through a conversation via e-mail or Discord',
      cost: 100,
      imageUri: 'https://cdn.shopify.com/s/files/1/0558/6413/1764/files/Game_Logo_Designs_28_1024x1024.png?v=1655916264',
      imageLoaded: true
    },
    {
      title: 'More Items Coming Soon...',
      description: 'Stay tuned for more items to purchase in the store!',
      cost: 0,
      imageUri: loadingImagePlaceholderPath,
      imageLoaded: true
    }
  ]);

  onBeforeMount(async () => {
    // Retrieving the user
    try {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Necessary for passing of login cookies from backend
      })

      // Check for 403 error
      if (response.status === 403) {
        await store.dispatch('setAuth', false);
        await navigateToSignUpPage();
      }

      const content = await response.json();

      if (content.detail === 'Unauthenticated!') {
        await store.dispatch('setAuth', false);
        await navigateToSignUpPage();
      } else {
        await store.dispatch('setAuth', true);
      }
    } catch (e) {
      await store.dispatch('setAuth', false);
      await navigateToSignUpPage();
    }

    authenticated.value = true;
    await getUserPoints();

    // Set the font size of the gameKarmaPointsHolder
    if (userGameKarmaPoints.value.toString().length > 3) {
      gameKarmaPointsHolderFontSize.value = '60';
    }

    loading.value = false;
  });


  // const showTooltip = async (event) => {
  //   const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
  //     tooltip.classList.remove('opacity-0', 'invisible');
  //     tooltip.classList.add('opacity-100', 'visible');
  // }

  // const hideTooltip = async (event) => {
  //   const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
  //       tooltip.classList.remove('opacity-100', 'visible');
  //       tooltip.classList.add('opacity-0', 'invisible');
  // }

  const navigateToSignUpPage = () => {
    store.dispatch('setIntent', '/store');
    router.push('/signup');
  }

  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getUserPoints = async () => {
    // Make the API call
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-points/`,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

      if (response.data) {
        userGameKarmaPoints.value = response.data.points_balance;
      }

    } catch (error) {
      console.error('Could not retrieve the points for the user.', error);
    }
  }

  const purchaseItem = async (item) => {

    // If userGameKarmaPoints is less than the cost of the item, we do not 
    //allow the purchase
    if (userGameKarmaPoints.value < item.cost) {
      notification.value = 'You do not have enough GP Coins to purchase this item.';
      notificationPresent.value = true;

      setTimeout(() => {
        notificationPresent.value = false;
      }, 3000);

      return;
    }
    
    // Make the API call
    try {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/make-purchase/`, 
        {
          item_id: item.title,
          cost: item.cost
        },
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

        // Check for status 200
        if (response.status === 200) {
 
            // Update the user's points
            userGameKarmaPoints.value = response.data.points_balance;

            // Show a notification
            notification.value = 'Item purchased successfully! We will send you an e-mail with your purchased item in a few business days.';
            notificationPresent.value = true;
    
            setTimeout(() => {
                notificationPresent.value = false;
            }, 6000);
        }

    } catch (error) {
      console.error('Could not purchase the item.', error);
    }
  }






</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div v-if="loading">
        <div class="flex justify-center items-center h-96">
          <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
        </div>
      </div>
      <div v-else-if="!loading && authenticated">
        <div class="h1 text-xl font-bold mb-8"><h1>Store</h1></div>

        <div class="flex flex-col lg:flex-row justify-between items-start">
        <!-- Left Column -->
        <div class="w-full lg:w-1/2 flex justify-center mb-4 lg:mb-0">
          <div class="flex items-center gap-2">
            <b>Your Loot</b>
            <span class="text-primary">
              <svg width="30" height="30" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <!-- Background circle -->
                <circle cx="100" cy="100" r="90" fill="gold" stroke="orange" stroke-width="5" />

                <!-- Inner circle -->
                <circle cx="100" cy="100" r="70" fill="goldenrod" />

                <!-- Placeholder text -->
                <text x="100" y="105" font-family="Arial" font-weight="bold" :font-size="gameKarmaPointsHolderFontSize" fill="white" text-anchor="middle" dominant-baseline="middle">
                  {{ userGameKarmaPoints }}
                </text>
              </svg>
            </span>
            GP Coins
          </div>

          <!-- <div class="hs-tooltip inline-block text-white text-xs mb-10">
            <button @mouseover="showTooltip" @mouseout="hideTooltip" type="button" class="hs-tooltip-toggle">
                <b>(What's this?)</b>
                <span ref="tooltipContent" class="hs-tooltip-content opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 text-left text-xs font-bold bg-secondary border-yellow-300" role="tooltip">
                GameKarma Points - Our way of rewarding you for your contributions to the community.
                You earn GameKarma points by rating/reviewing games, upvoting/downvoting posts,
                winning competitions, and making the community better overall.
                You can use these points to unlock premium features, rewards,
                and later on - buy gear for your gaming avatar!
                </span>
            </button>
          </div> -->
        </div>

        <!-- Right Column -->
        <div class="w-full lg:w-1/2 pl-0 lg:pl-4 text-center">
          <div class="items-center">
            <div>
              <b>Want to gain more coins?</b>
            </div>
            <div class="mt-2 mb-4">
              <ul class="list-disc list-outside mx-auto inline-block text-sm text-left ml-24">
                <router-link to="/profile">
                  <li><span class="text-primary hover:underline cursor-pointer">Invite Friends</span> (+100 GP)</li>
                </router-link>
                <li>Rate (+1 GP) and review (+10 GP) games</li>
                <li>Upvote and downvote posts (+1 GP)</li>
                <router-link to="/competitions">
                  <li><span class="text-primary hover:underline cursor-pointer">Win Competitions</span> (+500 GP)</li>
                </router-link>
              </ul>
            </div>
          </div>
        </div>
      </div>


        <div v-if="itemsToPurchase.length > 0" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 mx-4 gap-6 place-items-center">
            <div
            v-for="(item, index) in itemsToPurchase"
            :key="index"
            class="w-32 relative flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark hover:border-1 hover:border-[#42b983] hover:shadow-xl hover:shadow-[#42b983]/50 transition-all duration-200"
            >
                <div>
                    <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="item.imageLoaded ? item.imageUri : loadingImagePlaceholderPath" @load="item.imageLoaded = true" alt="The image of the item to be purchased">
                </div>
                <div class="py-2 px-1 max-h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                    <h3 class="text-xs font-bold text-white pt-2">{{ item.title }}</h3>
                </div>

                <hr class="mb-1" />

                <div class="px-1 flex justify-center text-center text-xs py-2 flex-col">
                    <span class="text-center">{{ item.description }}</span>
                </div>

                <div v-if="item.title !== 'More Items Coming Soon...'" class="px-1 flex justify-center text-center text-xs py-2 flex-col">
                    <span class="text-center">
                        Cost <span class="text-primary">
                            {{ addCommasToNumber(item.cost) }}
                        </span>
                    </span>
                </div>
                
                <div v-if="item.title !== 'More Items Coming Soon...'" class="px-1 flex justify-center text-center text-xs py-2 flex-col">
                    <button @click="purchaseItem(item)" 
                            class="bg-primary text-white font-bold py-2 px-4 rounded">
                        Purchase
                    </button>
                </div>
            </div>
        </div>


        <!-- NOTIFICATION (STARTS) -->
        <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
            <div class="p-4 relative">
            <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <div class="mb-2 text-center flex justify-center items-center">
                <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
            </div>

            <div>
                <p class="text-lg text-left text-gray-200 pl-2">{{ notification }}</p>
            </div>

            </div>
        </div>
        <!-- NOTIFICATION (ENDS) -->


      </div>
      <FooterComponent />
    </div>
  </body>
</template>

<style scoped>

.hs-tooltip .hs-tooltip-content {
    width: 300px; /* Fixed width */
    border: 1px solid gold; /* Gold border */
    border-radius: 5px; /* Rounded corners */
    color: white; /* White text */
    background-color: #111725; /* Adjust background color as needed */
    z-index: 100; /* Place the tooltip above other elements */
    transition: opacity 0.3s ease-in-out;
  }

  .hs-tooltip-content.opacity-0 {
    opacity: 0;
    visibility: hidden;
  }
  .hs-tooltip-content.opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .hs-tooltip-toggle {
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  }

</style>
