<script setup>
  import axios from 'axios';

  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';

  axios.defaults.withCredentials = true;

  const store = useStore();

  const router = useRouter();

  const loading = ref(true);
  const gamesLoading = ref(true);

  const expandedIndexRecentGames = ref(null);
  const expandedIndexTopGames = ref(null);
  const isLargeScreen = ref(window.innerWidth > 768);

  const authenticated = computed(() => store.state.authenticated);
  const user = ref(null);
  const visitorIsSelf = ref(false);

  const notificationPresent = ref(false);
  const notification = ref(null);

  const userXboxGamerTag = ref(null);
  const userGameKarmaPoints = ref(null);
  const userDescription = ref(null);

  const userXboxProfileUrl = ref(null);
  const userXboxGamerScore = ref(null);

  // Used for the user updating their description/socials
  const updateInfoPresent = ref(false);
  const infoToUpdate = ref(null);
  const typeOfInfo = ref(null);
  const infoInstructions = ref('Enter your message here...');

  const socialLinks = ref({
    xbox: null,
    discord: null,
    twitch: null, 
    steam: null,
    playstation: null,
    reddit: null,
    youtube: null,
    twitter: null,
    instagram: null,
    tiktok: null
  })

  const sectionShowing = ref('Recent Games');

  const gamesRecentlyPlayed = ref([]);
  const gamesTopPlayed = ref([]);


  onMounted(async () => {
    loading.value = true;

    await getUser();

    const userXboxGamerTagUri = await window.location.pathname.split('/').pop();
    userXboxGamerTag.value = decodeURIComponent(userXboxGamerTagUri);

    if (user.value && user.value.xbox_gamertag === userXboxGamerTag.value) {
      visitorIsSelf.value = true;
    }

    const ableToGetPoints = await getUserPoints();

    if (!ableToGetPoints) {
      loading.value = false;
      return;
    }

    await getUserSocialProfileInfo();
    await getUserRecentlyPlayedDetails();

    gamesLoading.value = false;
    loading.value = false;
  })


  const getUser = async () => {
    // Retrieving the user
    try {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Necessary for passing of login cookies from backend
      })

      // Check for 403 error
      if (response.status === 403) {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      }

      const content = await response.json();

      if (content.detail === 'Unauthenticated!') {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      } else {
        await store.dispatch('setAuth', true);
        user.value = content.user;
      }
    } catch (e) {
      await store.dispatch('setAuth', false);
      await navigateToLoginPage();
    }
  }

  const navigateToLoginPage = () => {
      router.push('/login');
  }

  const getUserPoints = async () => {
    try {

      // Define the payload data
      const inputData = {
        user_xbox_gamertag: userXboxGamerTag.value
      };

      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/get-any-user-points/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        })

      if (response.status === 200 && response.data) {

        userGameKarmaPoints.value = response.data.total_points_accumulated;
      } else {
        console.error('Could not retrieve the points for the user.', response);
        return false;
      }

    } catch (error) {
      console.error('Could not retrieve the points for the user.', error);
      return false;
    }

    return true;
  }


  const getUserSocialProfileInfo = async () => {

    // Define the payload data
    const inputData = {
      user_xbox_gamertag: userXboxGamerTag.value
    };

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-social-profile-info/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        })

      if (response.status === 200) {
        const userSocialProfileInfo = response.data;

        if (userSocialProfileInfo.description) {
          userDescription.value = userSocialProfileInfo.description;
        }

        if (userSocialProfileInfo.instagram_details) {
          socialLinks.value.instagram = userSocialProfileInfo.instagram_details;
        }

        if (userSocialProfileInfo.discord_details) {
          socialLinks.value.discord = userSocialProfileInfo.discord_details;
        }

        if (userSocialProfileInfo.reddit_details) {
          socialLinks.value.reddit = userSocialProfileInfo.reddit_details;
        }

        if (userSocialProfileInfo.playstation_details) {
          socialLinks.value.playstation = userSocialProfileInfo.playstation_details;
        }

        if (userSocialProfileInfo.steam_details) {
          socialLinks.value.steam = userSocialProfileInfo.steam_details;
        }

        if (userSocialProfileInfo.tiktok_details) {
          socialLinks.value.tiktok = userSocialProfileInfo.tiktok_details;
        }

        if (userSocialProfileInfo.twitch_details) {
          socialLinks.value.twitch = userSocialProfileInfo.twitch_details;
        }

        if (userSocialProfileInfo.twitter_details) {
          socialLinks.value.twitter = userSocialProfileInfo.twitter_details;
        }

        // For now this is the user's gamertag id
        socialLinks.value.xbox = userXboxGamerTag.value

        if (userSocialProfileInfo.youtube_details) {
          socialLinks.value.youtube = userSocialProfileInfo.youtube_details;
        }

      } else {
        console.error(
          'There was an error trying to get user social profile info!', 
          response
        );
      }

    } catch (error) {
      console.error(
        'There was an error trying to get user social profile info!', 
        error
      );
    }
  }

  const getUserRecentlyPlayedDetails = async () => {
    try {

      const inputData = {
        user_xbox_gamertag: userXboxGamerTag.value
      };

      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-recently-played-details/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        })

      if (response.status === 200) {
        const resData = response.data;

        if (resData.user_gamer_score) {
          userXboxGamerScore.value = resData.user_gamer_score;
        }

        if (resData.user_display_pic) {
          userXboxProfileUrl.value = resData.user_display_pic;
        }

        const recentlyPlayedGames = resData.user_recently_played_games;

        if (recentlyPlayedGames.length > 0) {
            recentlyPlayedGames.forEach((game) => {
            gamesRecentlyPlayed.value.push(game);
          });
        }

      } else {
        console.error(
          'There was an error trying to get user recently played details!', 
          response
        );
      }

    } catch (error) {
      console.error(
        'There was an error trying to get user recently played details!', 
        error
      );
    }
  }

  const getUserTopGamesPlayedDetails = async () => {
    try {

      const inputData = {
        user_xbox_gamertag: userXboxGamerTag.value
      };

      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-top-games-details/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        })

      if (response.status === 200) {
        const resData = response.data;

        const topPlayedGamesRes = resData.user_top_played_games;

        if (topPlayedGamesRes.length > 0) {
          topPlayedGamesRes.forEach((game) => {
            gamesTopPlayed.value.push(game);
          });
        }

      } else {
        console.error(
          'There was an error trying to get user recently played details!', 
          response
        );
      }

    } catch (error) {
      console.error(
        'There was an error trying to get user recently played details!', 
        error
      );
    }
  }


  const toggleAchievements = async (index, game_id, type_of_games) => {
    if (type_of_games === 'Recent Games') {
      gamesRecentlyPlayed.value[index].achievements_loading = true;

      // First we must retrieve the achievements for that given game if the achievements don't exist
      if (!gamesRecentlyPlayed.value[index].user_achievements) {
        // Make the API call
        await getGameAchievements(index, game_id, 'Recent Games');
      } else {
        expandedIndexRecentGames.value = expandedIndexRecentGames.value === index ? null : index;
        gamesRecentlyPlayed.value[index].achievements_loading = false;
        return;
      }

      expandedIndexRecentGames.value = expandedIndexRecentGames.value === index ? null : index;
      gamesRecentlyPlayed.value[index].achievements_loading = false;
      return;
    } else if (type_of_games === 'Top Games') {
      gamesTopPlayed.value[index].achievements_loading = true;

      // First we must retrieve the achievements for that given game if the achievements don't exist
      if (!gamesTopPlayed.value[index].user_achievements) {
        // Make the API call
        await getGameAchievements(index, game_id, 'Top Games');
      } else {
        expandedIndexTopGames.value = expandedIndexTopGames.value === index ? null : index;
        gamesTopPlayed.value[index].achievements_loading = false;
        return;
      }

      expandedIndexTopGames.value = expandedIndexTopGames.value === index ? null : index;
      gamesTopPlayed.value[index].achievements_loading = false;
      return;
    }

    return;
  };

  const getGameAchievements = async (index, game_id, type_of_game) => {
    // Define the payload data
    const inputData = {
      user_xbox_gamertag: userXboxGamerTag.value,
      game_id: game_id
    };

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/get-achievements-for-game/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        })

      if (response.status === 200) {
        if (type_of_game === 'Recent Games') {
          gamesRecentlyPlayed.value[index].user_achievements = response.data['user_achievements'];
        } else if (type_of_game === 'Top Games') {
          gamesTopPlayed.value[index].user_achievements = response.data['user_achievements'];
        }
      } else {
        console.error('Error fetching game achievement details:', response);
      }

    } catch (error) {
      console.error(
        'There was an error trying to get game achievement details!', 
        error
      );
    }
  }

  const updateInfoModal = async (info, type) => {

    if (type === 'description') {
      infoInstructions.value = 'Write a friendly message for your fellow gamers and friends...';
    } else {
      infoInstructions.value = `Enter your ${type} profile link or username here...`;
    }

    infoToUpdate.value = info;
    typeOfInfo.value = type;
    updateInfoPresent.value = true;
  }

  const updateUserInfo = async (info, type) => {

    const validSubmission = validateSubmissionText(info);

    if (!validSubmission) {
      return;
    }

    // Define the payload data
    const inputData = {
      new_info: info,
      type_of_info: type
    };

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BACKEND_API_ROUTE}/update-user-information/`, 
        inputData,
        {
          headers: {
              'Content-Type': 'application/json'
          },
          withCredentials: true,
        })

      if (response.status === 200) {
        updateInfoPresent.value = false;

        if (type === 'description') {
          userDescription.value = info;
        } else {
          socialLinks.value[type] = info;
        }

      } else {
        console.error('Error updating user info:', response);
      }

    } catch (error) {
      console.error(
        'There was an error trying to update user info!', 
        error
      );
    }
  }

  const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const loadRecentGames = async () => {
    gamesLoading.value = true;

    if (gamesRecentlyPlayed.value.length === 0) {
      await getUserRecentlyPlayedDetails();
    }

    sectionShowing.value = 'Recent Games';
    
    gamesLoading.value = false;
  }

  const loadTopGames = async () => {
    gamesLoading.value = true;

    if (gamesTopPlayed.value.length === 0) {
      await getUserTopGamesPlayedDetails();
    } 

    sectionShowing.value = 'Top Games';

    gamesLoading.value = false;
  }

  const validateSubmissionText = async (submissionText) => {

      let validSubmission = true;
      let invalidTextMessage = `This submission needs to be fixed, please try again by fixing the following:\n`;

      // Check that submissionText is non null
      if (submissionText === null || submissionText === '') {
        validSubmission = false;
        invalidTextMessage += `You need to write a submission for the game.\n`;
      }

      // Check for profanity or inappropriate language

      // TODO: To improve this code and import as library or use LLM
      // These words have been taken from the following source:
      // https://github.com/LDNOOBW/List-of-Dirty-Naughty-Obscene-and-Otherwise-Bad-Words/tree/master?tab=readme-ov-file
      const bannedWords = [
        '2g1c',
        '2 girls 1 cup',
        'acrotomophilia',
        'alabama hot pocket',
        'alaskan pipeline',
        'anal',
        'anilingus',
        'anus',
        'apeshit',
        'arsehole',
        'ass',
        'asshole',
        'assmunch',
        'auto erotic',
        'autoerotic',
        'babeland',
        'baby batter',
        'baby juice',
        'ball gag',
        'ball gravy',
        'ball kicking',
        'ball licking',
        'ball sack',
        'ball sucking',
        'bangbros',
        'bangbus',
        'bareback',
        'barely legal',
        'barenaked',
        'bastard',
        'bastardo',
        'bastinado',
        'bbw',
        'bdsm',
        'beaner',
        'beaners',
        'beaver cleaver',
        'beaver lips',
        'beastiality',
        'bestiality',
        'big black',
        'big breasts',
        'big knockers',
        'big tits',
        'bimbos',
        'birdlock',
        'bitch',
        'bitches',
        'black cock',
        'blonde action',
        'blonde on blonde action',
        'blowjob',
        'blow job',
        'blow your load',
        'blue waffle',
        'blumpkin',
        'bollocks',
        'bondage',
        'boner',
        'boob',
        'boobs',
        'booty call',
        'brown showers',
        'brunette action',
        'bukkake',
        'bulldyke',
        'bullet vibe',
        'bullshit',
        'bung hole',
        'bunghole',
        'busty',
        'butt',
        'buttcheeks',
        'butthole',
        'camel toe',
        'camgirl',
        'camslut',
        'camwhore',
        'carpet muncher',
        'carpetmuncher',
        'chocolate rosebuds',
        'cialis',
        'circlejerk',
        'cleveland steamer',
        'clit',
        'clitoris',
        'clover clamps',
        'clusterfuck',
        'cock',
        'cocks',
        'coprolagnia',
        'coprophilia',
        'cornhole',
        'coon',
        'coons',
        'creampie',
        'cuck',
        'cuckhold',
        'cum',
        'cumming',
        'cumshot',
        'cumshots',
        'cunnilingus',
        'cunt',
        'darkie',
        'date rape',
        'daterape',
        'deep throat',
        'deepthroat',
        'dendrophilia',
        'dick',
        'dildo',
        'dingleberry',
        'dingleberries',
        'dirty pillows',
        'dirty sanchez',
        'doggie style',
        'doggiestyle',
        'doggy style',
        'doggystyle',
        'dog style',
        'dolcett',
        'domination',
        'dominatrix',
        'dommes',
        'donkey punch',
        'double dong',
        'double penetration',
        'dp action',
        'dry hump',
        'dvda',
        'eat my ass',
        'ecchi',
        'ejaculation',
        'erotic',
        'erotism',
        'escort',
        'eunuch',
        'fag',
        'faggot',
        'fecal',
        'felch',
        'fellatio',
        'feltch',
        'female squirting',
        'femdom',
        'figging',
        'fingerbang',
        'fingering',
        'fisting',
        'foot fetish',
        'footjob',
        'frotting',
        'fuck',
        'fuck buttons',
        'fuckin',
        'fucking',
        'fucktards',
        'fudge packer',
        'fudgepacker',
        'futanari',
        'gangbang',
        'gang bang',
        'gay sex',
        'genitals',
        'giant cock',
        'girl on',
        'girl on top',
        'girls gone wild',
        'goatcx',
        'goatse',
        'god damn',
        'gokkun',
        'golden shower',
        'goodpoop',
        'goo girl',
        'goregasm',
        'grope',
        'group sex',
        'g-spot',
        'guro',
        'hand job',
        'handjob',
        'hard core',
        'hardcore',
        'hentai',
        'homoerotic',
        'honkey',
        'hooker',
        'horny',
        'hot carl',
        'hot chick',
        'how to kill',
        'how to murder',
        'huge fat',
        'humping',
        'incest',
        'intercourse',
        'jack off',
        'jail bait',
        'jailbait',
        'jelly donut',
        'jerk off',
        'jigaboo',
        'jiggaboo',
        'jiggerboo',
        'jizz',
        'juggs',
        'kike',
        'kinbaku',
        'kinkster',
        'kinky',
        'knobbing',
        'leather restraint',
        'leather straight jacket',
        'lemon party',
        'livesex',
        'lolita',
        'lovemaking',
        'make me come',
        'male squirting',
        'masturbate',
        'masturbating',
        'masturbation',
        'menage a trois',
        'milf',
        'missionary position',
        'mong',
        'motherfucker',
        'mound of venus',
        'mr hands',
        'muff diver',
        'muffdiving',
        'nambla',
        'nawashi',
        'negro',
        'neonazi',
        'nigga',
        'nigger',
        'nig nog',
        'nimphomania',
        'nipple',
        'nipples',
        'nsfw',
        'nsfw images',
        'nude',
        'nudity',
        'nutten',
        'nympho',
        'nymphomania',
        'octopussy',
        'omorashi',
        'one cup two girls',
        'one guy one jar',
        'orgasm',
        'orgy',
        'paedophile',
        'paki',
        'panties',
        'panty',
        'pedobear',
        'pedophile',
        'pegging',
        'penis',
        'phone sex',
        'piece of shit',
        'pikey',
        'pissing',
        'piss pig',
        'pisspig',
        'playboy',
        'pleasure chest',
        'pole smoker',
        'ponyplay',
        'poof',
        'poon',
        'poontang',
        'punany',
        'poop chute',
        'poopchute',
        'porn',
        'porno',
        'pornography',
        'prince albert piercing',
        'pthc',
        'pubes',
        'pussy',
        'queaf',
        'queef',
        'quim',
        'raghead',
        'raging boner',
        'rape',
        'raping',
        'rapist',
        'rectum',
        'reverse cowgirl',
        'rimjob',
        'rimming',
        'rosy palm',
        'rosy palm and her 5 sisters',
        'rusty trombone',
        'sadism',
        'santorum',
        'scat',
        'schlong',
        'scissoring',
        'semen',
        'sex',
        'sexcam',
        'sexo',
        'sexy',
        'sexual',
        'sexually',
        'sexuality',
        'shaved beaver',
        'shaved pussy',
        'shemale',
        'shibari',
        'shit',
        'shitblimp',
        'shitty',
        'shota',
        'shrimping',
        'skeet',
        'slanteye',
        'slut',
        's&m',
        'smut',
        'snatch',
        'snowballing',
        'sodomize',
        'sodomy',
        'spastic',
        'spic',
        'splooge',
        'splooge moose',
        'spooge',
        'spread legs',
        'spunk',
        'strap on',
        'strapon',
        'strappado',
        'strip club',
        'style doggy',
        'suck',
        'sucks',
        'suicide girls',
        'sultry women',
        'swastika',
        'swinger',
        'tainted love',
        'taste my',
        'tea bagging',
        'threesome',
        'throating',
        'thumbzilla',
        'tied up',
        'tight white',
        'tit',
        'tits',
        'titties',
        'titty',
        'tongue in a',
        'topless',
        'tosser',
        'towelhead',
        'tranny',
        'tribadism',
        'tub girl',
        'tubgirl',
        'tushy',
        'twat',
        'twink',
        'twinkie',
        'two girls one cup',
        'undressing',
        'upskirt',
        'urethra play',
        'urophilia',
        'vagina',
        'venus mound',
        'viagra',
        'vibrator',
        'violet wand',
        'vorarephilia',
        'voyeur',
        'voyeurweb',
        'voyuer',
        'vulva',
        'wank',
        'wetback',
        'wet dream',
        'white power',
        'whore',
        'worldsex',
        'wrapping men',
        'wrinkled starfish',
        'xx',
        'xxx',
        'yaoi',
        'yellow showers',
        'yiffy',
        'zoophilia',
        '🖕',
      ]; 

      const containsProfanity = bannedWords.some(word => 
        new RegExp(`\\b${word}\\b`, 'i').test(submissionText)
      );

      if (containsProfanity) {
        invalidTextMessage += `Submission contains inappropriate language.\n`
        validSubmission = false;
      }

      // Check for HTML or JavaScript content
      const containsHTMLorJS = /<\/?[a-z][\s\S]*>/i.test(submissionText);

      if (containsHTMLorJS) {
        invalidTextMessage += `Submission contains potential HTML or JavaScript content.\n`
        validSubmission = false;
      }

      // Check for SQL Injection patterns
      const sqlInjectionPatterns = [
        /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|ALTER)\b\s*)/i, // SQL commands
        /(\b(OR|AND)\b\s*\d+=\d+)/i, // Conditional injection patterns
      ];

      const containsSQLInjection = sqlInjectionPatterns.some(pattern => pattern.test(submissionText));

      if (containsSQLInjection) {
        invalidTextMessage += `Submission contains potential SQL injection patterns.\n`;
        validSubmission = false;
      }

      if (!validSubmission) {
        notification.value = invalidTextMessage;
        notificationPresent.value = true;
      }

      // Check if the text does not exceed 254 characters
      if (submissionText !== null && submissionText.length > 254) {
        invalidTextMessage += `Submission exceeds the maximum character limit of 254.`;
        validSubmission = false;
      }

      if (!validSubmission) {
        notification.value = invalidTextMessage;
        notificationPresent.value = true;
      }

      return validSubmission;
    }


</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div>
        <div class="text-xl font-bold mb-10">
          <div>{{ userXboxGamerTag }}</div>
          <div v-if="loading" class="mt-10 text-center">
            <div class="animate-spin inline-block size-6 w-10 h-10 border-[3px] border-current border-t-transparent rounded-full text-white" role="status" aria-label="loading">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>

        <div v-if="!loading && authenticated" class="text-left mx-auto pl-4">
          <!-- New Wrapper Div for Two Columns -->
          <div class="flex justify-between w-full mt-4">
              <!-- Left Column -->
              <div class="w-1/2 pr-2">
                  <!-- Avatar Image -->
                  <img :src="userXboxProfileUrl" alt="Avatar" class="w-24 h-24 rounded-full mb-4">

                  <!-- XBL Score -->
                  <div class="text-sm mb-2">
                      <strong>XBL Score:</strong> {{ formatNumber(userXboxGamerScore) }}
                  </div>

                  <!-- GameKarmaPoints -->
                  <div class="text-sm">
                      <strong>GameKarmaPoints:</strong> {{ userGameKarmaPoints }}
                  </div>

                  <!-- Status Timeline -->
                  <div class="text-sm mt-8">
                      <div class="font-bold">Message to Fellow Gamers</div>
                      <div class="break-all pt-2">{{ userDescription }}</div>
                      <div @click="updateInfoModal(userDescription, 'description')"  class="text-gray-400 hover:text-green-500 cursor-pointer" v-if="visitorIsSelf">
                        <font-awesome-icon 
                          :icon="['fas', 'edit']" />
                      </div>
                  </div>
              </div>

              <!-- Right Column -->
              <div class="w-1/2 pl-2">

            <!-- Right Column -->
            <div class="w-1/2 pl-2">
                <div class="social-links">
                  <div v-for="(link, platform) in socialLinks" :key="platform" class="flex items-center mb-3">
                    <div class="inline" v-if="link || visitorIsSelf">
                      <font-awesome-icon class="mr-2" :icon="['fab', platform]" />
                      <span v-if="link" class="text-gray-300 break-all text-xs">
                          <span class="mr-2">{{ link }}</span><span class="text-gray-400 hover:text-green-500 cursor-pointer" v-if="visitorIsSelf">
                              <font-awesome-icon 
                                v-if="platform != 'xbox'"
                                @click="updateInfoModal(link, platform)" 
                                :icon="['fas', 'edit']" />
                          </span>
                      </span>
                      <span v-else-if="!link && visitorIsSelf" 
                            @click="updateInfoModal(link, platform)"
                            class="text-gray-400 hover:text-green-500 cursor-pointer text-xs">
                          Connect
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2 pl-3 text-center">
            <button @click="loadRecentGames()" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-left py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-l-lg border bg-secondary text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Recent Games' ? 'bg-primary' : 'bg-secondary']">
              Recent Games
            </button>
            <button @click="loadTopGames()" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-right py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Top Games' ? 'bg-primary' : 'bg-secondary']">
              Top Games
            </button>
          </div>
          <div v-if="gamesLoading" class="container mx-auto my-10 p-4 text-center">
            <div class="animate-spin inline-block size-6 w-10 h-10 border-[3px] border-current border-t-transparent rounded-full text-white" role="status" aria-label="loading">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else class="container mx-auto p-4">
            <!-- Header Row -->
            <div class="bg-gray-400 shadow-lg rounded-lg p-4 mb-2">
              <div class="flex items-center justify-between space-x-4">
                <div class="text-xs sm:text-lg font-semibold text-gray-700 text-left w-4/12">Game</div>
                <div class="text-xs sm:text-lg font-semibold text-gray-700 w-2/12">Progress</div>
                <div class="text-xs sm:text-lg font-semibold text-gray-700 w-2/12">{{ isLargeScreen ? '' : 'More Info' }}</div> <!-- Blank column for button -->
              </div>
            </div>
            <div v-if="sectionShowing === 'Recent Games'">
              <div 
                v-for="(gameProgress, index) in gamesRecentlyPlayed" 
                :key="index" 
                class="bg-gray-300 shadow-lg rounded-lg mb-2 p-4"
              >
                <!-- First Row -->
                <div class="flex items-center justify-between space-x-4">
                  <div class="text-xs sm:text-lg font-semibold text-gray-700 h-15 overflow-y-auto text-left w-4/12 flex items-center space-x-2">
                    <img 
                      :src="gameProgress.game_image_url" 
                      alt="Game Image" 
                      class="w-20 h-20 rounded-lg"
                    />
                    <span class="pl-3">{{ gameProgress.game_title }}</span>
                  </div>
                  <div class="text-sm sm:text-lg text-gray-600 w-2/12">{{ (gameProgress.game_progress_percentage.toFixed(0)) }}%</div>
                  <div class="text-sm sm:text-lg text-gray-600 w-2/12">
                    <button 
                      v-if="isLargeScreen"
                      class="my-3 mx-1 text-xs text-gray-700 inline-flex justify-center items-center rounded-md border border-gray-700 font-semibold transition-all p-2" 
                      @click="toggleAchievements(index, gameProgress.game_id, 'Recent Games')"
                    >
                      <div v-if="!gameProgress.achievements_loading">{{ expandedIndexRecentGames === index ? 'Hide Achievements' : 'Show Achievements' }}</div>
                      <div v-else class="w-24">
                        <div class="animate-spin inline-block size-6 w-6 h-6 border-[2px] border-current border-t-transparent rounded-full text-black" role="status" aria-label="loading">
                          <div class="sr-only">Loading...</div>
                        </div>
                      </div>
                    </button>
                    <button 
                      v-else
                      class="my-3 mx-1 text-xs text-gray-700 inline-flex justify-center items-center font-semibold transition-all p-2" 
                      @click="toggleAchievements(index, gameProgress.game_id, 'Recent Games')"
                    >
                      <div v-if="!gameProgress.achievements_loading"><font-awesome-icon :icon="['fas', 'trophy']" /></div>
                      <div v-else>
                        <div class="animate-spin inline-block size-6 w-6 h-6 border-[2px] border-current border-t-transparent rounded-full text-black" role="status" aria-label="loading">
                          <div class="sr-only">Loading...</div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

                <!-- Second Row (Expandable Achievements) -->
                <div v-if="expandedIndexRecentGames === index && gameProgress.user_achievements.length > 0" 
                    class="mt-4 bg-gray-200 rounded-lg p-4">
                  <div 
                    v-for="(achievement, aIndex) in gameProgress.user_achievements" 
                    :key="aIndex" 
                    class="flex items-center justify-between space-x-4 py-2 border-b border-gray-600 last:border-none"
                  >
                    <div class="w-full flex items-center space-x-2">
                      <img 
                        :src="achievement.image_url" 
                        alt="Achievement" 
                        class="w-10 h-10 rounded-lg"
                      />
                      <div 
                        class="text-xs sm:text-base font-bold overflow-y-auto text-left"
                        :class="{
                          'text-gray-800': Math.abs(achievement.rarity_percentage) > 60 && Math.abs(achievement.rarity_percentage) <= 100,  // Common
                          'text-green-500': Math.abs(achievement.rarity_percentage) > 30 && Math.abs(achievement.rarity_percentage) <= 60,  // Rare
                          'text-purple-500': Math.abs(achievement.rarity_percentage) > 10 && Math.abs(achievement.rarity_percentage) <= 30,  // Very Rare
                          'text-yellow-500': Math.abs(achievement.rarity_percentage) > 0 && Math.abs(achievement.rarity_percentage) <= 10  // Legendary
                        }"
                      >
                        {{ achievement.name }}<span class="text-xs text-gray-700"> - {{ achievement.description }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="expandedIndexRecentGames === index && gameProgress.user_achievements.length === 0 && gameProgress.game_progress_percentage > 0" class="mt-4 bg-gray-200 rounded-lg p-4">
                  <p class="py-2 text-black">We could not find any achievements for this game - likely because this is an older Xbox 360 game</p>
                </div>
                <div v-else-if="expandedIndexRecentGames === index && gameProgress.user_achievements.length === 0" class="mt-4 bg-gray-200 rounded-lg p-4">
                  <p class="py-2 text-black">You don't have any achievements yet for this game, go and get some!</p>
                </div>
              </div>


            </div>
            <div v-else-if="sectionShowing === 'Top Games'">
              <div 
                v-for="(gameProgress, index) in gamesTopPlayed" 
                :key="index" 
                class="bg-gray-300 shadow-lg rounded-lg mb-2 p-4"
              >
                <!-- First Row -->
                <div class="flex items-center justify-between space-x-4">
                  <div class="text-xs sm:text-lg font-semibold text-gray-700 h-15 overflow-y-auto text-left w-4/12 flex items-center space-x-2">
                    <img 
                      :src="gameProgress.game_image_url" 
                      alt="Game Image" 
                      class="w-20 h-20 rounded-lg"
                    />
                    <span class="pl-3">{{ gameProgress.game_title }}</span>
                  </div>
                  <div class="text-sm sm:text-lg text-gray-600 w-2/12">{{ (gameProgress.game_progress_percentage.toFixed(0)) }}%</div>
                  <div class="text-sm sm:text-lg text-gray-600 w-2/12">
                    <button 
                      v-if="isLargeScreen"
                      class="my-3 mx-1 text-xs text-gray-700 inline-flex justify-center items-center rounded-md border border-gray-700 font-semibold transition-all p-2" 
                      @click="toggleAchievements(index, gameProgress.game_id, 'Top Games')"
                    >
                      <div v-if="!gameProgress.achievements_loading">{{ expandedIndexTopGames === index ? 'Hide Achievements' : 'Show Achievements' }}</div>
                      <div v-else class="w-24">
                        <div class="animate-spin inline-block size-6 w-6 h-6 border-[2px] border-current border-t-transparent rounded-full text-black" role="status" aria-label="loading">
                          <div class="sr-only">Loading...</div>
                        </div>
                      </div>
                    </button>
                    <button 
                      v-else
                      class="my-3 mx-1 text-xs text-gray-700 inline-flex justify-center items-center font-semibold transition-all p-2" 
                      @click="toggleAchievements(index, gameProgress.game_id, 'Top Games')"
                    >
                      <div v-if="!gameProgress.achievements_loading"><font-awesome-icon :icon="['fas', 'trophy']" /></div>
                      <div v-else>
                        <div class="animate-spin inline-block size-6 w-6 h-6 border-[2px] border-current border-t-transparent rounded-full text-black" role="status" aria-label="loading">
                          <div class="sr-only">Loading...</div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

                <!-- Second Row (Expandable Achievements) -->
                <div v-if="expandedIndexTopGames === index && gameProgress.user_achievements.length > 0" 
                    class="mt-4 bg-gray-200 rounded-lg p-4">
                  <div 
                    v-for="(achievement, aIndex) in gameProgress.user_achievements" 
                    :key="aIndex" 
                    class="flex items-center justify-between space-x-4 py-2 border-b border-gray-600 last:border-none"
                  >
                    <div class="w-full flex items-center space-x-2">
                      <img 
                        :src="achievement.image_url" 
                        alt="Achievement" 
                        class="w-10 h-10 rounded-lg"
                      />
                      <div 
                        class="text-xs sm:text-base font-bold overflow-y-auto text-left"
                        :class="{
                          'text-gray-800': Math.abs(achievement.rarity_percentage) > 60 && Math.abs(achievement.rarity_percentage) <= 100,  // Common
                          'text-green-500': Math.abs(achievement.rarity_percentage) > 30 && Math.abs(achievement.rarity_percentage) <= 60,  // Rare
                          'text-purple-500': Math.abs(achievement.rarity_percentage) > 10 && Math.abs(achievement.rarity_percentage) <= 30,  // Very Rare
                          'text-yellow-500': Math.abs(achievement.rarity_percentage) > 0 && Math.abs(achievement.rarity_percentage) <= 10  // Legendary
                        }"
                      >
                        {{ achievement.name }}<span class="text-xs text-gray-700"> - {{ achievement.description }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="expandedIndexTopGames === index && gameProgress.user_achievements.length === 0 && gameProgress.game_progress_percentage > 0" class="mt-4 bg-gray-200 rounded-lg p-4">
                  <p class="py-2 text-black">We could not find any achievements for this game - likely because this is an older Xbox 360 game</p>
                </div>
                <div v-else-if="expandedIndexTopGames === index && gameProgress.user_achievements.length === 0" class="mt-4 bg-gray-200 rounded-lg p-4">
                  <p class="py-2 text-black">You don't have any achievements yet for this game, go and get some!</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- NOTIFICATION (STARTS) -->
      <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
        <div class="p-4 relative">
          <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="mb-2 text-center flex justify-center items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div class="ms-3">
            <p class="text-lg text-left text-gray-200 pl-2">{{ notification }}</p>
          </div>
        </div>
      </div>
      <!-- NOTIFICATION (ENDS) -->

      <!-- TIP GAME (STARTS) -->
      <div v-if="updateInfoPresent" class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-10" role="alert">
        <div class="p-4 relative">
          <button @click="updateInfoPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="text-left">
            <div class="mt-3 font-bold">What would you like this to be?</div>
            <div class="mt-3 max-w-sm space-y-3">
              <textarea v-model="infoToUpdate" class="py-3 px-4 block w-full rounded-lg text-sm focus:border-green-500 disabled:opacity-50 disabled:pointer-events-none bg-neutral-900 border-neutral-700 text-neutral-200 placeholder-neutral-400 focus:ring-neutral-600" rows="5" :placeholder="infoInstructions"></textarea>
            </div>
            <div class="mt-3">
              <div class="text-center mt-3">
                <button 
                  @click="updateUserInfo(infoToUpdate, typeOfInfo)" 
                  type="button" 
                  class="mt-3 mx-1 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800">
                    Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TIP GAME (ENDS) -->

      <FooterComponent />
    </div>
  </body>
</template>

<style scoped>

  .game-list-btn-selector {
    border-top: 2px solid #42b983; 
    border-bottom: 2px solid #42b983; 
    border-left: 1px solid #42b983; 
    border-right: 1px solid #42b983; 
    color: inherit; 
  }

  .game-list-btn-selector-left {
    border-left: 2px solid #42b983; 
    color: inherit; 
  }

  .game-list-btn-selector-right {
    border-right: 2px solid #42b983; 
    color: inherit; 
  }

  .game-list-btn-selector:hover {
    background-color: #42b983; 
  }

  .game-list-btn-selector:focus {
    background-color: #42b983; 
    outline: none; 
  }

  .hs-tooltip .hs-tooltip-content {
    width: 300px; /* Fixed width */
    border: 1px solid gold; /* Gold border */
    border-radius: 5px; /* Rounded corners */
    color: white; /* White text */
    background-color: #111725; /* Adjust background color as needed */
    z-index: 100; /* Place the tooltip above other elements */
    transition: opacity 0.3s ease-in-out;
  }

  .hs-tooltip-content.opacity-0 {
    opacity: 0;
    visibility: hidden;
  }
  .hs-tooltip-content.opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .hs-tooltip-toggle {
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  }

  




</style>
