<script setup>
  import axios from 'axios';

  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';

  axios.defaults.withCredentials = true;


  const router = useRouter();
  const store = useStore();
  
  const notificationPresent = ref(false);
  const notification = ref(null);

  const loading = ref(false);
  
  const user = ref(null);
  const userQuota = ref(null);

  const userGameKarmaPoints = ref(null);
  const profileUrl = ref(null);
  const friendShareableUrl = ref(null);


  onMounted(async () => {
    loading.value = true;

    // Retrieving the user
    try {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Necessary for passing of login cookies from backend
      })

      // Check for 403 error
      if (response.status === 403) {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      }

      const content = await response.json();

      if (content.detail === 'Unauthenticated!') {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      } else {
        await store.dispatch('setAuth', true);
        user.value = content.user;
      }
    } catch (e) {
      await store.dispatch('setAuth', false);
      await navigateToLoginPage();
    }

    // We next get the user's points
    await getUserPoints();

    // Next we get check and reset the user's game picking quota
    // await checkAndResetSubscriptionQuota();

    // We next get the user's leftover quota
    await getUserSubscriptionQuota();

    // We get the shareable link for the user
    await getFriendInviteShareableLink();

    // Creating the profile url the user has the gamertag set up
    if (user.value && user.value.xbox_gamertag) {
      profileUrl.value = `${window.location.origin}/social/profiles/${user.value.xbox_gamertag}`;
    }

    loading.value = false;
  })

  const authenticated = computed(() => store.state.authenticated);

  const navigateToLoginPage = () => {
      router.push('/login');
  }

  const navigateToGamePickerPage = () => {
      router.push('/');
  }

  const showTooltip = async (event) => {
    const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-0', 'invisible');
      tooltip.classList.add('opacity-100', 'visible');
  }

  const hideTooltip = async (event) => {
    const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
        tooltip.classList.remove('opacity-100', 'visible');
        tooltip.classList.add('opacity-0', 'invisible');
  }

  const getUserSubscriptionQuota = async () => {
    // Make the API call
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-subscription-quota/`,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

      if (response.data) {
        userQuota.value = response.data;
      }

    } catch (error) {
      console.error('Could not retrieve the subscription quota for the user.', error);
    }
  }

  const getUserPoints = async () => {
    // Make the API call
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-points/`,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

      if (response.data) {
        userGameKarmaPoints.value = response.data.points_balance;
      }

    } catch (error) {
      console.error('Could not retrieve the points for the user.', error);
    }
  }

  const getFriendInviteShareableLink = async () => {
    // Make the API call
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-friend-link-share/`,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

      if (response.data && response.data.invite_nonce) {
        const shareableLinkFirstPart = `${window.location.origin}/signup?friend_referral=`;
        friendShareableUrl.value = `${shareableLinkFirstPart}${response.data.invite_nonce}`;
      }

    } catch (error) {
      console.error('Could not retrieve the shareable link for the user.', error);
    }
  }

  const shareUrlInitiated = async (shareUrl) => {
    await navigator.clipboard.writeText(shareUrl);

    notification.value = 'Shareable link copied to clipboard. Share this with your friends to gain +100 GameKarma points for each friend who joins!';
    notificationPresent.value = true;
  }

  const profileUrlInitiated = async (profileUrl) => {
    await navigator.clipboard.writeText(profileUrl);

    notification.value = 'Profile link copied to clipboard. Share this with your friends to show off your gaming profile!';
    notificationPresent.value = true;
  }

  const addNewGamertag = async (gamerTag) => {
      if (!authenticated.value) {
          console.log('You must be logged in to add your gamer tag.');
          return;
      }

      // Define the payload data
      const inputData = {
          xbox_gamertag: gamerTag,
      };

      try {
          
          // Make the API call using await
          const response = await axios.post(
            `${process.env.VUE_APP_BACKEND_API_ROUTE}/add-new-xbox-gamertag/`, 
            inputData,
              {
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  withCredentials: true,
              });

            if (response.status === 200) {
              const gamerTagSuccess = response.data.xbox_gamertag;
              user.value.xbox_gamertag = gamerTagSuccess;
              profileUrl.value = `${window.location.origin}/social/profiles/${gamerTagSuccess}`;
            }

      } catch (error) {
          // Handle error
          console.error('We could not add your gamertag at this time', error);
      }
  }

  const logout = async () => {
      try {
      await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/logout`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
      });

      await store.dispatch('setAuth', false);
      authenticated.value = false;
      await navigateToGamePickerPage();

      } catch (e) {
          authenticated.value = false;
          console.log('Failed to logout')
          console.log(e);
      }
  }

</script>

<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div>
        <div class="text-xl font-bold mb-10">Profile</div>
        <div v-if="!loading && authenticated && user" class="text-left lg:w-4/6 mx-auto pl-4">
            <div>
                <div class="text-primary font-bold">
                  <span>GameKarma Points </span>
                  <div class="hs-tooltip inline-block text-white text-xs">
                    <button @mouseover="showTooltip" @mouseout="hideTooltip" type="button" class="hs-tooltip-toggle">
                      (What's this?)
                      <span ref="tooltipContent" class="hs-tooltip-content opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 text-left text-xs font-bold bg-secondary border-yellow-300" role="tooltip">
                        GameKarma Points - Our way of rewarding you for your contributions to the community.
                        You earn GameKarma points by rating/reviewing games, upvoting/downvoting posts, 
                        winning competitions, and making the community better overall.
                        You can use these points to unlock premium features, rewards,
                        and later on - buy gear for your gaming avatar!
                      </span>
                    </button>
                  </div>
                </div>
                <div v-if="userGameKarmaPoints !== null" class="flex items-center">
                  <span>
                    <svg width="50" height="50" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <!-- Background circle -->
                      <circle cx="100" cy="100" r="90" fill="gold" stroke="orange" stroke-width="5" />

                      <!-- Inner circle -->
                      <circle cx="100" cy="100" r="70" fill="goldenrod" />

                      <!-- Placeholder text -->
                      <text x="100" y="105" font-family="Arial" font-weight="bold" font-size="60" fill="white" text-anchor="middle" dominant-baseline="middle">
                        {{ userGameKarmaPoints }}
                      </text>
                    </svg>
                  </span>

                  <span class="pl-2 text-sm">
                    Spend these in the 
                    <router-link to="/store" 
                                 class="text-primary hover:underline">
                      <strong>Store</strong>
                    </router-link>
                  </span>
                </div>
            </div>

            <div>
                <div class="text-primary font-bold mt-3">Username / XB Gamertag</div>
                <div>
                    <div v-if="user.xbox_gamertag">
                      {{ user.xbox_gamertag }}
                      <span class="pl-2 text-white cursor-pointer" data-hs-overlay="#hs-add-gamertag-modal">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>
                    </div>
                    <div v-else class="text-xs">
                      <button class="bg-transparent text-white px-1 py-1 mx-1 my-1 border border-white rounded" data-hs-overlay="#hs-add-gamertag-modal">
                        Add GamerTag
                      </button>
                    </div>
                </div>
            </div>

            <div>
                <div class="text-primary font-bold mt-3">Social Profile<span v-if="user.xbox_gamertag" class="text-white text-xs"> (Show Your Friends)</span></div>
                <div>
                    <span v-if="user.xbox_gamertag">
                      <router-link :to="`/social/profiles/${user.xbox_gamertag}`" class="text-sm hover:underline text-white break-all" active-class="active-link">
                        {{ profileUrl }} 
                      </router-link> <font-awesome-icon @click="profileUrlInitiated(profileUrl)" class="cursor-pointer" :icon="['fas', 'copy']" />
                    </span> 
                    <span v-else class="text-xs">You must add your Username / XB Gamertag first</span>
                </div>
            </div>

            <div>
                <div class="text-primary font-bold mt-3">Invite Friends<span v-if="user.xbox_gamertag" class="text-white text-xs"> (Shareable Link)</span></div>
                <div>
                    <span @click="shareUrlInitiated(friendShareableUrl)" class="text-sm cursor-pointer hover:underline">
                    {{ friendShareableUrl }} <font-awesome-icon :icon="['fas', 'copy']" />
                    </span>
                </div>
            </div>

            <div class="mt-3">
                <div class="text-primary font-bold">Email</div>
                <div>{{ user.email }}</div>
            </div>
            <div class="text-center mt-3">
                <button @click="logout" type="button" class="my-3 mx-1 inline-flex justify-center items-center rounded-md border border-primary font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-2 ring-offset-gray-800">
                    Logout
                </button>
            </div>
        </div>
        <div v-else-if="loading" class="text-center text-white text-lg mt-10">
          Loading...
        </div>

      </div>


      <!-- ADD GAMERTAG MODAL (STARTS) -->
      <div id="hs-add-gamertag-modal" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
        <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
            <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
            <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
              <h3 class="font-bold text-white text-left">
                Add your Xbox GamerTag
              </h3>
            </div>
            <div class="m-4">
              <label for="gamerTag" class="block text-sm mb-2 text-white text-left">GamerTag</label>
              <input id="gamerTag" v-model="xboxGamerTag" type="text" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 border-gray-700 text-gray-400">
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
              <button @click.prevent="addNewGamertag(xboxGamerTag)" type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border-primary font-medium bg-primary shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-primary border-gray-700 text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-add-gamertag-modal">
                Submit
              </button>
              <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-add-gamertag-modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- ADD GAMERTAG MODAL (ENDS) -->

        <!-- NOTIFICATION (STARTS) -->
        <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
            <div class="p-4 relative">
            <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <div class="mb-2 text-center flex justify-center items-center">
                <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
            </div>

            <div>
                <p class="text-lg text-left text-gray-200 pl-2">{{ notification }}</p>
            </div>

            </div>
        </div>
        <!-- NOTIFICATION (ENDS) -->

      <FooterComponent />
    </div>
  </body>
</template>

<style scoped>

  .hs-tooltip .hs-tooltip-content {
    width: 300px; /* Fixed width */
    border: 1px solid gold; /* Gold border */
    border-radius: 5px; /* Rounded corners */
    color: white; /* White text */
    background-color: #111725; /* Adjust background color as needed */
    z-index: 100; /* Place the tooltip above other elements */
    transition: opacity 0.3s ease-in-out;
  }

  .hs-tooltip-content.opacity-0 {
    opacity: 0;
    visibility: hidden;
  }
  .hs-tooltip-content.opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .hs-tooltip-toggle {
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  }


</style>
