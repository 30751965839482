<template>

<body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />

        <!-- ========== MAIN CONTENT ========== -->
        <main id="content">
            <div class="text-center py-10 px-4 sm:px-6 lg:px-8">
                <h1 class="block text-7xl font-bold sm:text-9xl text-white">404</h1>
                <p class="mt-3 text-neutral-400">Oops, something went wrong.</p>
                <p class="text-neutral-400">Sorry, we couldn't find your page.</p>
                <div class="px-1 flex justify-center text-center text-sm py-10">
                    <button @click="browseToTheHomePage" 
                            class="bg-primary text-white font-bold py-2 px-4 rounded">
                        Back to the Game Picker
                    </button>
                </div>
            </div>
        </main>
        <!-- ========== END MAIN CONTENT ========== -->

        <FooterComponent />
    </div>
  </body>
</template>

<script setup>

    import { useRouter } from 'vue-router';

    import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
    import FooterComponent from '@/components/Layout/FooterComponent.vue';

    const router = useRouter();


    const browseToTheHomePage = () => {
        router.push('/');
    }

</script>

<style scoped>

</style>